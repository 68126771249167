<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Reportes Log</h4>
            <div class="small text-muted">Muestra un historial de cada reporte generado.</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col md="11">            
            <b-row>              
              <b-col md="3" class="pr-0">
                <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="3" class="pl-0">
                <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>              

              <b-col sm="3" class="pl-2">
                <v-select :options="arr.filters.reports" v-model="filters.report" placeholder="Reportes" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="3" class="pl-2">
                <v-select :options="arr.filters.staff" v-model="filters.staff" placeholder="Staff" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>                
            </b-row>
          </b-col>
          <b-col md="1">
            <b-button variant="outline-dark" @click="filter()" size="sm" class="mt-1">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-table class="mb-0"
                     responsive="sm"
                     head-variant="dark"
                     :hover="true"
                     :small="true"
                     :fixed="true"
                     :items="table.items"
                     :fields="table.fields"
                     :filter="table.filter"
                     :current-page="table.currentPage"
                     :per-page="table.perPage"                     
                     :busy="table.isBusy"                      
                     v-if="table.items.length || table.isBusy">                     

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template> 

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <strong>{{data.item.id}}</strong>
                </template>

                <template v-slot:cell(date)="data">                  
                  {{moment(data.item.date).format('DD/MM/YYYY HH:mm')}}
                </template>

                <template v-slot:cell(title)="data">                  
                  <strong>{{data.item.title}}</strong>
                </template>

                <template v-slot:cell(staff_id)="data">
                  {{data.item.staff.name}}
                </template>

                <template v-slot:cell(metadata)="data">
                  <div v-if="data.item.metadata">                    
                    <b-badge v-for="(element, index) in data.item.metadata.split('|')" 
                             :key="index" href="javascript:void(0);" 
                             variant="light"
                             class="ml-1"
                             style="font-size:12px;">
                      {{element}}
                    </b-badge>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="sendPrinter(data.item)">
                      <b-icon icon="printer"></b-icon> Imprimir
                    </b-dropdown-item>                      
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filter()" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.pdf" 
              :pdf="crud.print.pdf"               
              :key="crud.print.key"/>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import moment from 'moment'
  import Printer from '@/components/inc/printer/printer'

  export default {
    components: {
      FindObject,
      Printer
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.REPORTES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'log',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', class: 'align-middle', width: '5%'},
            {key: 'date', label: 'Fecha', class: 'align-middle text-left', width: '10%'},
            {key: 'staff_id', label: 'Staff', class: 'align-middle text-left', width: '15%'},
            {key: 'title', label: 'Nombre', class: 'align-middle text-left', width: '15%'},            
            {key: 'metadata', label: 'Detalle', class: 'align-middle text-left', width: '50%'},     
            {key: 'f_action', label:'', class: 'align-middle', width: '5%'},
          ],
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                  
        }, 
        crud: {
          print: {
            pdf: '',            
            key: 0,
          }      
        },  
        arr: {
          filters: {
            staff: [],          
            reports: [],         
          }            
        },
        filters: {    
          date_start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          date_end: moment().format('YYYY-MM-DD'),
          staff: '', 
          report: '', 
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {    
      this.filterLoad()        
    },    
    methods: {   
      filterLoad() {
        this.filterLoadStaff()
        this.filterLoadReports()
      },
      filterLoadStaff() {
        var result = serviceAPI.obtenerStaff()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.staff = []
          data.forEach(element => {        
            if(element.id == Helper.getStaffId()) {
              this.filters.staff = { code: element.id, label: element.name }
            }
            this.arr.filters.staff.push({ code: element.id, label: element.name })                        
          });
          
          this.filter()
        })   
      },      
      filterLoadReports() {
        var result = serviceAPI.obtenerReports()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.reports = []
          data.forEach(element => {         
            this.arr.filters.reports.push({ code: element.id, label: element.name })            
          });          
        })   
      },           
      filter() {
        this.table.isBusy = true
        var result = serviceAPI.getFilterReportLog(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.items = data.data
          this.table.tablePaginate = data
       
          this.table.isBusy = false                 
        })
        .catch(error => {
          this.table.isBusy = false          
          this.$awn.alert(Error.showError(error))
        });        
      },

      sendPrinter(item) {
        this.crud.print.key = Math.random()
        this.crud.print.pdf = item.url
      }
    }    
  }
</script>